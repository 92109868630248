import moment from "moment";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

export default function Admintable({ data }) {

  const navigate = useNavigate();

  const Project = (row) => (
    <>
      <p style={{ color: "#61d9ca" }}>#{row?.projectId} </p>
    </>
  );

  const Customer = (row) => (
    <>
      <p style={{ color: "#61d9ca" }}>{row?.name} </p>
    </>
  );

  const Company = (row) => (
    <>
      <p style={{ color: "#61d9ca" }}>{row?.businessName} </p>
    </>
  );

  const SignUp = (row) => (
    <>
      <p style={{ color: "#61d9ca" }}>{moment(row?.createdAt).format('DD-MM-YYYY')}</p>
    </>
  );

  const columns = [
    { name: "Project ID", cell: Project, sortable: true },
    { name: "Client's Name", cell: Customer, sortable: true },
    { name: "COMPANY", cell: Company, sortable: true },
    { name: "SIGNUP DATE", cell: SignUp, sortable: true },
    {
      name: "ACTIONS",
      cell: (row) => (
        <button onClick={() => navigate(`/admin/profile/${row?.id}`, { state: { setupAccount: true } })} className="text-white button bg-warning border border-none rounded p-2">
          Setup
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      pagination
      paginationPerPage={5}
      paginationRowsPerPageOptions={[5, 10, 20]}
      responsive={true}
    />
  );
}
