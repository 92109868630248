import { get, post, put, deleted } from '../index';
import ChatbotRoutes from './Chatbot.Routes';

const ChatbotServices = {
  getChatbotSetting: async (params) => {
    const data = await get(ChatbotRoutes.getChatbotSetting, params);
    return data;
  },
  updateChatbotSetting: async (obj) => {
    const data = await post(ChatbotRoutes.updateChatbotSetting, obj);
    return data;
  },
  uploadChatbotAvatar: async (id, obj) => {
    const data = await post(ChatbotRoutes.uploadChatbotAvatar + `projectId=${id}`, obj);
    return data;
  },
  uploadChatbotBg: async (id, obj) => {
    const data = await post(ChatbotRoutes.uploadChatbotBg + `projectId=${id}`, obj);
    return data;
  },
  getChatbot: async (params) => {
    const data = await get(ChatbotRoutes.getChatbot, params);
    return data;
  },
  getRunningChatbot: async (params) => {
    const data = await get(ChatbotRoutes.getRunningChatbot, params);
    return data;
  },
  getExpiredChatbot: async (params) => {
    const data = await get(ChatbotRoutes.getExpiredChatbot, params);
    return data;
  },
  getToConfigureChatbot: async (params) => {
    const data = await get(ChatbotRoutes.getToConfigureChatbot, params);
    return data;
  },
  deleteChatbot: async (id) => {
    const data = await deleted(ChatbotRoutes.deleteChatbot + id);
    return data;
  },
}

export default ChatbotServices