import {
  IconBrandPaypal,
  IconCalendar,
  IconCurrencyDollar,
  IconSettings,
  IconUsers,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import CustomerServices from "../api/customer/CustomerService";
import DashboardServices from "../api/dashboard/DashboardService";
import "../assets/css/admin.css";
import Admintable from "../components/Admintable";
import Chartboard from "../components/Chartboard";
import Donutchart from "../components/Donutchart";
import { ErrorToaster } from "../components/Toaster";
import Spinner from "../components/spinner";
import Layout from "../layout";

export default function DashboardAdmin() {
  const [data, setData] = useState({});

  const [customers, setCustomers] = useState([]);
  const [loader, setLoader] = useState(false);

  const getDashboardStats = async () => {
    try {
      const result = await DashboardServices.dashboard();
      setData(result);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const getCustomers = async () => {
    setLoader(true);
    try {
      let params = {
        page: 1,
        limit: 250,
        order: "desc",
        configuredByAdmin: true,
      };
      const { result } = await CustomerServices.getCustomers(params);
      setCustomers(result);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getDashboardStats();
    getCustomers();
  }, []);

  return (
    <Layout>
      <div className="layout-page">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y responsive-width-control">
            <h4 className="py-3 mb-2"></h4>
            <div className="row">
              <div className="col-lg-6 mb-4 col-md-12">
                <div className="card h-100">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="card-title mb-0 fw-bold">All Time User</h5>
                  </div>
                  <div className="card-body pt-2">
                    <div className="row gy-3">
                      <div className="col-md-4 col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-primary me-3 p-2">
                            <IconUsers />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold ">
                              {data?.allTimeUsers?.totalUsers || 0}
                            </h5>
                            <small>Total Users</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-info me-3 p-2">
                            <IconUsers />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">
                              {data?.allTimeUsers?.activeUsers || 0}
                            </h5>
                            <small>Active Users</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-danger me-3 p-2">
                            <IconUsers />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">
                              {data?.allTimeUsers?.inActiveUsers || 0}
                            </h5>
                            <small>Inactive Users</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-4 col-md-12">
                <div className="card h-100">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="card-title mb-0 fw-bold">
                      All Time Revenue
                    </h5>
                  </div>
                  <div className="card-body pt-2">
                    <div className="row gy-3">
                      <div className="col-md-4 col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-primary me-3 p-2">
                            <IconCurrencyDollar />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">
                              ${data?.allTimeRevenue?.totalRevenue || 0}
                            </h5>
                            <small>Total Revenue</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-info me-3 p-2">
                            <IconSettings />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">
                              ${data?.allTimeRevenue?.setupFees || 0}
                            </h5>
                            <small>Setup Fees</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-danger me-3 p-2">
                            <IconCalendar />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">
                              ${data?.allTimeRevenue?.subsFees || 0}
                            </h5>
                            <small>Subscriptions</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 mb-4 col-md-12">
                <div className="card h-100">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="card-title mb-0 fw-bold">This Month</h5>
                  </div>
                  <div className="card-body pt-2">
                    <div className="row gy-3">
                      <div className="col-lg col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-info me-3 p-2">
                            <IconUsers />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">
                              {data?.thisMonth?.newUsers || 0}
                            </h5>
                            <small>New Users</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-primary me-3 p-2">
                            <IconCurrencyDollar />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">
                              ${data?.thisMonth?.revenue || 0}
                            </h5>
                            <small>Revenue</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-info me-3 p-2">
                            <IconSettings />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">
                              ${data?.thisMonth?.setupFees || 0}
                            </h5>
                            <small>Setup Fees</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-warning me-3 p-2">
                            <IconCalendar />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">
                              ${data?.thisMonth?.subsFees || 0}
                            </h5>
                            <small>Subscriptions</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-info me-3 p-2">
                            <IconBrandPaypal />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">
                              ${data?.thisMonth?.commissions || 0}
                            </h5>
                            <small>Commission</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-md-8">
                <div className="card h-100">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="card-title mb-0 fw-bold">Sales Team</h5>
                  </div>
                  <div className="card-body pt-2">
                    <div className="row gy-3">
                      <div className="col-md-3 col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-info me-3 p-2">
                            <IconUsers />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">0</h5>
                            <small>Country Managers</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-primary me-3 p-2">
                            <IconUsers />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">0</h5>
                            <small>Regional Managers</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-warning me-3 p-2">
                            <IconUsers />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">0</h5>
                            <small>Area Developers</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-danger me-3 p-2">
                            <IconUsers />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">0</h5>
                            <small>Sales Reps</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <PieChart />
                  </div>
                </div>
              </div>
            </div> */}
            <br />

            {/* <div className="row">
              <div className="col-lg-12 mb-4 col-md-12">
                <div className="card h-100">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="card-title mb-0 fw-bold">
                      Sales Team Commission
                    </h5>
                  </div>
                  <div className="card-body pt-2">
                    <div className="row gy-3">
                      <div className="col-lg col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-info me-3 p-2">
                            <IconCurrencyDollar />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">$0</h5>
                            <small>Commission Earned</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-info me-3 p-2">
                            <IconSettings />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">$0</h5>
                            <small>Setup </small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-danger me-3 p-2">
                            <IconCalendar />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">$0</h5>
                            <small>Paid</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-primary me-3 p-2">
                            <IconCash />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">$0</h5>
                            <small>Paid</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-info me-3 p-2">
                            <IconBrandPaypal />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">$0</h5>
                            <small>Balance</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg">
                <div className="card h-100 p-3">
                  {data?.usersRegisteredLast30Days && (
                    <Chartboard data={data?.usersRegisteredLast30Days} />
                  )}
                </div>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-md-4 col-sm-12">
                <div className="card h-100">
                  <div className="card-info p-3">
                    <h4 className="fw-bold">Plan Subscribed</h4>
                    {data?.planSubscribed && (
                      <Donutchart data={data?.planSubscribed} />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-sm">
                <div className="card h-100">
                  <div className="card-info p-3 responsive-style">
                    <h4 className="fw-bold">New Signup</h4>
                    <p>Setup New Chatbot</p>
                    {loader ? (
                      <Spinner size="large" />
                    ) : (
                      <Admintable data={customers} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </Layout>
  );
}
