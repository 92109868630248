import { IconTrash } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CustomerServices from "../api/customer/CustomerService";
import "../assets/css/admin.css";
import oneImage from "../assets/img/avatars/1.png";
import { ErrorToaster, SuccessToaster } from "../components/Toaster";
import AddSidebar from "../components/addSidebar";
import Spinner from "../components/spinner";
import { baseUrl } from "../config/axios";
import Layout from "../layout";

const AllCustomers = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [searchedCustomers, setSearchedCustomers] = useState([]);

  const getCustomers = async () => {
    setLoader(true);
    try {
      let params = {
        page: 1,
        limit: 250,
        order: "desc",
      };
      const { result } = await CustomerServices.getCustomers(params);
      setCustomers(result);
      setSearchedCustomers(result);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  const searchChatbot = (value) => {
    if (value) {
      let val = value?.toLowerCase();
      const filteredData = customers.filter((e) =>
        e?.name?.toLowerCase().includes(val)
      );
      setSearchedCustomers(filteredData);
    } else {
      setSearchedCustomers(customers);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  // for handling avator pics
  const avatorHandler = (row) => {
    return (
      <div
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => navigate(`/admin/profile/${row?.id}`)}
      >
        <img
          src={row.profilePic ? baseUrl + row?.profilePic : oneImage}
          alt={row.name}
          className="avatorImageCustom"
          style={{ width: "50px", height: "50px", marginRight: "10px" }}
        />
        <div>
          {row.name} <br />
          <small className="text-muted">{row.email}</small>
        </div>
      </div>
    );
  };

  const deleteCustomer = async (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this Customer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#FF0000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await CustomerServices.deleteCustomer(id);
        SuccessToaster("Customer Delete Successfully");
        getCustomers();
      }
    });
  };

  const ActionButtons = (row) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingRight: "30px",
      }}
    >
      <IconTrash
        size={20}
        style={{ cursor: "pointer" }}
        className="icon-style"
        onClick={() => deleteCustomer(row?.id)}
      />
    </div>
  );

  // columns for react-data-tables
  const columns = [
    {
      name: "Customer",
      cell: avatorHandler,
      minWidth: "300px",
    },

    {
      name: "Customer ID",
      selector: (row) => `#${row.id}`,
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) => `${row.country}`,
    },
    {
      name: "Signup Code",
      selector: (row) => row.signupCode,
      sortable: true,
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Actions",
      cell: ActionButtons,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <Layout>
      <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y responsive-width-control">
          <h4 class="py-3 mb-2">
            <span class="text-muted fw-light">Users /</span> All Customers
          </h4>

          {/* <!-- customers List Table --> */}
          <div class="card">
            <div class="card-header d-flex flex-wrap pb-md-2 justify-content-between px-5">
              <div class="d-flex align-items-center me-5">
                <div
                  id="DataTables_Table_0_filter"
                  class="dataTables_filter ms-n3"
                >
                  <label>
                    <input
                      type="search"
                      class="form-control"
                      placeholder="Search Order"
                      aria-controls="DataTables_Table_0"
                      onChange={(e) => searchChatbot(e.target.value)}
                    />
                  </label>
                </div>
              </div>
              {/* <div class="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end gap-3 gap-sm-0 flex-wrap flex-sm-nowrap pt-0">
                <div
                  class="dataTables_length ms-n2 mt-0 mt-md-3 me-2"
                  id="DataTables_Table_0_length"
                >
                  <select
                    name="DataTables_Table_0_length"
                    aria-controls="DataTables_Table_0"
                    class="form-select "
                    style={{
                      width: "75px",
                      marginBottom: ".89rem",
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div class="dt-buttons btn-group flex-wrap">
                  <button
                    className="dt-button buttons-collection dropdown-toggle btn btn-label-secondary me-3"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="d-flex items-center">
                      <IconDownload size={"18px"} className="me-2" />
                      Export
                    </span>
                    <span className="dt-down-arrow"></span>
                  </button>
                  <div
                    className="dropdown-menu w-25"
                    aria-labelledby="dropdownMenuButton"
                    aria-modal="true"
                    role="dialog"
                    style={{
                      top: "78.596px",
                      left: "889.797px",
                    }}
                  >
                    <DropOption
                      icon={<IconPrinter size={"23px"} className="me-2" />}
                      name={"Print"}
                    />
                    <DropOption
                      icon={<IconFile size={"23px"} className="me-2" />}
                      name={"CSv"}
                    />
                    <DropOption
                      icon={<IconFileText size={"23px"} className="me-2" />}
                      name={"Pdf"}
                    />
                    <DropOption
                      icon={<IconCopy size={"23px"} className="me-2" />}
                      name={"Copy"}
                    />
                  </div>

                  <button
                    class="btn btn-secondary add-new btn-primary rounded-2"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasEcommerceCustomerAdd"
                  >
                    <span>
                      <i class="ti ti-plus me-0 me-sm-1 mb-1 ti-xs"></i>
                      <span class="d-none d-sm-inline-block">Add Customer</span>
                    </span>
                  </button>
                </div>
              </div> */}
            </div>
            <div class="card-datatable table-responsive">
              {loader ? (
                <Spinner />
              ) : (
                <DataTable
                  columns={columns}
                  data={searchedCustomers}
                  pagination
                  paginationPerPage={10}
                  responsive={true}
                  selectableRows
                  overflowY={true}
                  highlightOnHover
                  className="secondaryCheckbox"
                />
              )}
            </div>
            {/* <!-- Offcanvas to add new customer --> */}
            <AddSidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AllCustomers;
