import { IconClockExclamation, IconMessageChatbot, IconSettings } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import ExpiredChatbot from "../components/expiredChatbot";
import RunningChatbot from "../components/runningChatbot";
import ToConfigureChatbot from "../components/toConfigureChatbot";
import Layout from "../layout";
import ChatbotServices from "../api/chatbot/ChatbotServices";
import { ErrorToaster, SuccessToaster } from "../components/Toaster";

// Common part for both scenarios
const NavLinks = ({ check, handleKey }) => (
  <ul className="nav nav-pills flex-column flex-md-row mb-4">
    <li className="nav-item " onClick={() => handleKey(1)}>
      <a
        className={`nav-link ${check === 1 && "active"}`}
        style={{ cursor: "pointer" }}
      >
        <IconMessageChatbot className="me-1" /> Running
      </a>
    </li>
    <li className="nav-item" onClick={() => handleKey(2)}>
      <a
        className={`nav-link ${check === 2 && "active"}`}
        style={{ cursor: "pointer" }}
      >
        <IconClockExclamation className="me-1" /> Expired
      </a>
    </li>
    <li className="nav-item" onClick={() => handleKey(3)}>
      <a
        className={`nav-link ${check === 3 && "active"}`}
        style={{ cursor: "pointer" }}
      >
        <IconSettings className="me-1" />
        To be Configure
      </a>
    </li>
    {/* Add more list items here for other scenarios */}
  </ul>
);

export default function ChatBot() {

  const [check, setCheck] = useState(1);

  const handleKey = (val) => {
    setCheck(val);
  };

  const [loader, setLoader] = useState(false);

  const [runningChatbot, setRunningChatbot] = useState();
  const [expiredChatbot, setExpiredChatbot] = useState();
  const [toConfigureChatbot, setToConfigureChatbot] = useState();

  const getRunningChatbot = async () => {
    setLoader(true)
    try {
      let params = {
        page: 1,
        limit: 10,
        order: 'desc'
      }
      const { result } = await ChatbotServices.getRunningChatbot(params)
      setRunningChatbot(result)
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }

  const getExpiredChatbot = async () => {
    setLoader(true)
    try {
      let params = {
        page: 1,
        limit: 10,
        order: 'desc'
      }
      const { result } = await ChatbotServices.getExpiredChatbot(params)
      setExpiredChatbot(result)
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }

  const getToConfigureChatbot = async () => {
    setLoader(true)
    try {
      let params = {
        page: 1,
        limit: 10,
        order: 'desc'
      }
      const { result } = await ChatbotServices.getToConfigureChatbot(params)
      setToConfigureChatbot(result)
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }

  const deleteChatbot = async (id) => {
    try {
      await ChatbotServices.deleteChatbot(id)
      SuccessToaster("Chatbot delete successfully")
      getRunningChatbot()
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    getRunningChatbot()
    getExpiredChatbot()
    getToConfigureChatbot()
  }, [])

  return (
    <Layout>
      <div className="content-wrapper">
        <div
          className="container-xxl flex-grow-1 container-p-y" >
          <h4 className="py-3 mb-4 fw-bold">
            <span className="text-muted ">ChatBots /</span>{" "}
            {check === 1
              ? "Running"
              : check === 2
                ? "Expired"
                : "To be Configure"}
          </h4>

          <div className="row">
            <div className="col-md-12">
              <NavLinks check={check} handleKey={handleKey} />
              {check === 1 && <RunningChatbot loader={loader} data={runningChatbot} deleteChatbot={deleteChatbot} />}
              {check === 2 && <ExpiredChatbot loader={loader} data={expiredChatbot} deleteChatbot={deleteChatbot} />}
              {check === 3 && <ToConfigureChatbot loader={loader} data={toConfigureChatbot} deleteChatbot={deleteChatbot} />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
