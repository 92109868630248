import {
  IconMessageChatbot,
  IconSettings,
  IconUser,
  IconUserCheck,
} from "@tabler/icons-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import "../assets/css/admin_profile.css";
import profilePhoto from "../assets/img/avatars/1.png";
import Banner from "../assets/img/pages/profile-banner.png";
import OnBoardTwo from "../components/onboard-two";
import { baseUrl } from "../config/axios";
import Layout from "../layout";
import Chatbot from "../pages/chatbot";
import { useForm } from "react-hook-form";
import { ErrorToaster, SuccessToaster } from "../components/Toaster";
import { IconUpload } from "@tabler/icons-react";
import CountryServices from "../api/country/CountryServices";
import CustomerServices from "../api/customer/CustomerService";
import Spinner from "../components/spinner";
import { IconMessageDots } from "@tabler/icons-react";
import { IconCreditCard } from "@tabler/icons-react";
import { IconPackage } from "@tabler/icons-react";
import { IconShoppingCart } from "@tabler/icons-react";
import { IconFileDescription } from "@tabler/icons-react";
import BillingPlan from "../components/billingsPlan";

const Profile = () => {

  const Navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();

  const [check, setCheck] = useState(1);
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [loader, setLoader] = useState(false);

  const [countries, setCountries] = useState([]);
  const [currentPlan, setCurrentPlan] = useState();
  const [paymentHistory, setPaymentHistory] = useState();

  const handleKey = (val) => {
    setCheck(val);
  };

  const [profileDetail, setProfileDetail] = useState();

  const getProfileDetail = async () => {
    setLoader(true)
    try {
      const { result } = await CustomerServices.getCustomerDetail(id)
      setProfileDetail(result)
      setValue('name', result?.name)
      setValue('email', result?.email)
      setValue('mobile', result?.mobile)
      setValue('businessName', result?.businessName)
      setValue('officeContact', result?.officeContact)
      setValue('address', result?.address)
      // setValue('country', result?.country)
      setValue('state', result?.state)
      setValue('city', result?.city)
      setValue('zipCode', result?.zipCode)
      setValue('singupCode', result?.singupCode)
      getCustomerCurrentPlan(result?.id)
      getCustomerPaymentHistory(result?.email)
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }

  const updateProfile = async (formData) => {
    try {
      const profileData = { ...formData }
      let obj = {
        email: profileDetail?.email,
        data: profileData
      }
      const { message } = await CustomerServices.updateCustomer(obj)
      SuccessToaster("Customer Updated Successfully")
    } catch (error) {
      ErrorToaster(error)
    }
  }

  const uploadProfilePicture = async (e) => {
    try {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      const { message, uploadedUrl } = await CustomerServices.updateCustomerImage(formData);
      SuccessToaster(message)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // const getCountries = async () => {
  //   try {
  //     const { data } = await CountryServices.getCountries()
  //     setCountries(data)
  //   } catch (error) {
  //     ErrorToaster(error)
  //   }
  // }

  const getCustomerCurrentPlan = async (id) => {
    try {
      const { result } = await CustomerServices.getCustomerCurrentPlan(id)
      setCurrentPlan(result)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  const getCustomerPaymentHistory = async (email) => {
    try {
      const { result } = await CustomerServices.getCustomerPaymentHistory(email)
      setPaymentHistory(result)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    // getCountries()
    getProfileDetail()
    if (state?.setupAccount) {
      setCheck(5)
    }
  }, []);

  return (
    <Layout>
      <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
          <h4 class="py-3 mb-4">
            <span class="text-muted fw-light">User Profile /</span> Profile
          </h4>

          {loader ? (
            <Spinner size='large' />
          ) : (
            <>
              {/* <!-- Header --> */}
              {/* <div class="row">
                <div class="col-12">
                  <div class="card mb-4">
                    <div class="user-profile-header-banner">
                      <img src={Banner} alt="Banner image" class="rounded-top" />
                    </div>
                    <div class="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                      <div class="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                        <img
                          src={
                            profileDetail?.profilePic
                              ? baseUrl + profileDetail?.profilePic
                              : profilePhoto
                          }
                          alt="user image"
                          class="d-block h-auto ms-0 ms-sm-4 rounded user-profile-img "
                        />
                      </div>
                      <div class="flex-grow-1 mt-3 mt-sm-5">
                        <div class="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                          <div class="user-profile-info">
                            <h4>{profileDetail?.name}</h4>
                            <ul class="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-2">
                              <li class="list-inline-item d-flex gap-1">
                                <IconColorSwatch
                                  size={"19px"}
                                  className="text-body"
                                />
                                {profileDetail?.businessName}
                              </li>
                              <li class="list-inline-item d-flex gap-1">
                                <IconMapPin size={"19px"} className="text-body" />
                                {profileDetail?.city}
                              </li>
                              <li class="list-inline-item d-flex gap-1">
                                <IconCalendar size={"19px"} className="text-body" />
                                Joined{" "}
                                {moment(profileDetail?.createdAt).format("MMMM YYYY")}
                              </li>
                            </ul>
                          </div>
                          <a href="javascript:void(0)" class="btn btn-primary">
                        <IconCheck size={"19px"} className="me-1" />
                        Connected
                      </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <!--/ Header --> */}

              {/* <!-- Navbar pills --> */}
              <div class="row">
                <div class="col-md-12">
                  <ul class="nav nav-pills flex-column flex-sm-row mb-4">
                    <li
                      class="nav-item cursor_pointer_custom"
                      onClick={() => handleKey(1)}
                    >
                      <a className={`nav-link ${check === 1 && "active"}`}>
                        <IconUserCheck size={"19px"} className="me-1" />
                        Profile & Business
                      </a>
                    </li>
                    <li
                      class="nav-item cursor_pointer_custom"
                      onClick={() => handleKey(2)}
                    >
                      <a className={`nav-link ${check === 2 && "active"}`}>
                        <IconMessageChatbot size={"19px"} className="me-1" />
                        ChatBots
                      </a>
                    </li>
                    <li className="nav-item cursor_pointer_custom" onClick={() => handleKey(3)}>
                      <a className={`nav-link ${check === 3 && "active"}`}>
                        <IconFileDescription className="me-1" />
                        Subscription Plans
                      </a>
                    </li>
                    <li className="nav-item cursor_pointer_custom" onClick={() => handleKey(4)}>
                      <a className={`nav-link ${check === 4 && "active"}`}>
                        <IconFileDescription className="me-1" />
                        Payment History
                      </a>
                    </li>
                    <li
                      className="nav-item cursor_pointer_custom"
                      onClick={() => handleKey(5)}
                    >
                      <a className={`nav-link ${check === 5 && "active"}`}>
                        <IconSettings className="me-1" />
                        Account Setup
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!--/ Navbar pills --> */}

              {/* <!-- User Profile Content --> */}
              {check === 1 ? (
                <div className="card mb-4">
                  <h5 className="card-header">Profile Details</h5>
                  <div className="card-body">
                    <div className="d-flex align-items-start align-items-sm-center gap-4">
                      <img
                        src={profileDetail?.profilePic ? baseUrl + profileDetail?.profilePic : profilePhoto}
                        alt="user-avatar"
                        className="d-block w-px-100 h-px-100 rounded"
                        id="uploadedAvatar"
                      />
                      {/* <div className="button-wrapper">
                        <label
                          for="upload"
                          className="btn btn-primary me-2 mb-3"
                          tabindex="0"
                        >
                          <span className="d-none d-sm-block">
                            Upload new photo
                          </span>
                          <IconUpload className="d-block d-sm-none" size={16} />
                          <input
                            type="file"
                            id="upload"
                            onChange={uploadProfilePicture}
                            className="account-file-input"
                            hidden
                            accept="image/png, image/jpeg"
                          />
                        </label>

                        <div className="text-muted">
                          Allowed JPG, GIF or PNG. Max size of 800K
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="card-body">
                    <form
                      id="formAccountSettings"
                      method="POST"
                      onsubmit="return false"
                    >
                      <div className="row">
                        <div className="mb-3 col-md-6">
                          <label for="name" className="form-label">
                            Name
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Name"
                            autofocus
                            {...register('name', {
                              required: 'Please enter name'
                            })}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label for="email" className="form-label">
                            E-mail
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="email"
                            name="email"
                            placeholder="email@example.com"
                            {...register('email', {
                              required: 'Please enter email address'
                            })}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label className="form-label" for="phoneNumber">
                            Mobile
                          </label>
                          <div className="input-group input-group-merge">
                            {/* <span className="input-group-text">US (+1)</span> */}
                            <input
                              type="text"
                              id="phoneNumber"
                              name="phoneNumber"
                              className="form-control"
                              placeholder="202 555 0111"
                              {...register('mobile')}
                            />
                          </div>
                        </div>
                        <div className="mb-3 col-md-6">
                          <label for="business" className="form-label">
                            Business Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="business"
                            name="business"
                            placeholder="Business Name"
                            {...register('businessName', {
                              required: 'Please enter business name'
                            })}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label className="form-label" for="phoneNumber">
                            Office Contact
                          </label>
                          <div className="input-group input-group-merge">
                            {/* <span className="input-group-text">US (+1)</span> */}
                            <input
                              type="text"
                              id="phoneNumber"
                              name="phoneNumber"
                              className="form-control"
                              placeholder="202 555 0111"
                              {...register('officeContact')}
                            />
                          </div>
                        </div>
                        <div className="mb-3 col-md-6">
                          <label for="address" className="form-label">
                            Address
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            placeholder="Address"
                            {...register('address')}
                          />
                        </div>
                        {/* <div className="mb-3 col-md-6">
                          <label className="form-label" for="country">
                            Country
                          </label>
                          <select
                            id="country"
                            className="select2 form-select"
                            {...register('country')}
                          >
                            <option value="">Select Country</option>
                            {countries?.map((item, index) => (
                              <option key={index} value={item?.name}>{item?.name}</option>
                            ))}
                          </select>
                        </div> */}
                        <div className="mb-3 col-md-6">
                          <label for="state" className="form-label">
                            State
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="state"
                            name="state"
                            placeholder="state"
                            {...register('state')}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label for="city" className="form-label">
                            City
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            placeholder="city"
                            {...register('city')}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label for="zipCode" className="form-label">
                            Zip Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="zipCode"
                            name="zipCode"
                            placeholder="231465"
                            maxlength="6"
                            {...register('zipCode')}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label for="zipCode" className="form-label">
                            Singup Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="singupCode"
                            name="singupCode"
                            {...register('singupCode')}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <button
                          type="button"
                          onClick={handleSubmit(updateProfile)}
                          className="btn btn-primary me-2"
                        >
                          Save changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : check === 2 ? (
                <div className="row">
                  <div className="col-lg-12 col-xxl-12 mb-4 order-xxl-1">
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <div className="card-title mb-0 profile-image-main flex-row d-flex gap-2">
                          <div className="profile-image prof-new">
                            <img className="w-100 rounded-circle" src={profileDetail?.profilePic ? baseUrl + profileDetail?.profilePic : profilePhoto} />
                          </div>
                          <div>
                            <h5 className="m-0 me-2">{profileDetail?.name}</h5>
                            <small className="text-muted">
                              Total number of Queries {profileDetail?.stats?.data?.total_queries}
                            </small>
                          </div>
                        </div>
                        <div>
                          <span className="mx-2">Last Updated {moment(profileDetail?.updatedAt).fromNow()}</span>
                          <IconSettings
                            size={18}
                            className="ml-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => Navigate(`/admin/chatbot-setting/${profileDetail?.id}/${profileDetail?.projectId}`)}
                          />
                          {/* <IconTrash
                            size={18}
                            className="ml-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteChatbot(item?.id)}
                          /> */}
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div className="container">
                          <hr />
                        </div>

                        <div className="row p-3">
                          <div className="col-lg-3 col-sm-6 mb-4">
                            <div className="card border-gray h-100 chatcard">
                              <div className="card-body p-3">
                                <div className="card-icon">
                                  <span className="badge bg-label-primary rounded-pill p-2">
                                    <IconUser size={16} />
                                  </span>
                                </div>
                                <h5 className="card-title mb-0 py-2 mt-2">{profileDetail?.stats?.data?.pages_found}</h5>
                                <p>Items Found</p>
                              </div>
                              <div id="subscriberGained"></div>
                            </div>
                          </div>

                          <div className="col-lg-3 col-sm-6 mb-4">
                            <div className="card border-gray h-100 chatcard">
                              <div className="card-body p-3">
                                <div className="card-icon">
                                  <span className="badge bg-label-danger rounded-pill p-2">
                                    <IconShoppingCart size={16} />
                                  </span>
                                </div>
                                <h5 className="card-title mb-0 py-2 mt-2">{profileDetail?.stats?.data?.pages_indexed}</h5>
                                <p>Items Indexed</p>
                              </div>
                              <div id="quarterlySales"></div>
                            </div>
                          </div>

                          <div className="col-lg-3 col-sm-6 mb-4">
                            <div className="card border-gray h-100 chatcard ">
                              <div className="card-body p-3">
                                <div className="card-icon">
                                  <span className="badge bg-label-warning rounded-pill p-2">
                                    <IconPackage size={16} />
                                  </span>
                                </div>
                                <h5 className="card-title mb-0 py-2 mt-2">{profileDetail?.stats?.data?.total_words_indexed}</h5>
                                <p>Word Indexed</p>
                              </div>
                              <div id="orderReceived"></div>
                            </div>
                          </div>

                          <div className="col-lg-3 col-sm-6 mb-4">
                            <div className="card border-gray h-100 chatcard">
                              <div className="card-body p-3">
                                <div className="card-icon">
                                  <span className="badge bg-label-success rounded-pill p-2">
                                    <IconCreditCard size={16} />
                                  </span>
                                </div>
                                <h5 className="card-title mb-0 py-2 mt-2">{profileDetail?.stats?.data?.total_queries}</h5>
                                <div>
                                  <p>Queries this month</p>
                                </div>
                              </div>
                              <div id="revenueGenerated"></div>
                            </div>
                          </div>
                        </div>
                        <button onClick={() => Navigate(`/admin/chat/${profileDetail?.id}`, { state: profileDetail })} className="w-100 rounded border m-0 p-3 bg-primary text-white">
                          <IconMessageDots className="mx-2" /> Talk To Kriss AI
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : check === 3 ? (
                <BillingPlan currentPlan={currentPlan} />
              ) : check === 4 ? (
                <div className="sm:px-8">
                  <div className="bg-white px-3 sm:rounded-lg shadow-sm divide-y divide-gray-100">
                    {paymentHistory?.map((item, index) => (
                      <div key={index} className="flex px-6 py-4 row">
                        <div className="col-xxl-6 col-md-6 col-sm-12">
                          <div className="text-sm ">{item?.customerId} {`(${item?.paymentInfo})`}</div>
                        </div>
                        <div className="col-xxl-3 col-md-3 col-sm-12">
                          <div className="ml-10 text-sm">
                            <span>${item?.paymentAmount}</span>
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-3 col-sm-12">
                          <div className="text-sm ">{moment(item?.createdAt).format('DD MMMM YYYY')}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <OnBoardTwo customerId={id} forProfile={true} /> // TODO : integrate customer id here
              )}
            </>
          )}
        </div>
      </div>
      {/* <!--/ User Profile Content --> */}
    </Layout>
  );
};

export default Profile;
