const ChatbotRoutes = {
  getChatbotSetting: 'admin/gpt/project/setting',
  updateChatbotSetting: 'admin/gpt/project/setting',
  uploadChatbotAvatar: 'admin/gpt/project/setting/uploadavatar?',
  uploadChatbotBg: 'admin/gpt/project/setting/uploadchatbotbg?',
  getChatbot: 'admin/chatbots',
  getRunningChatbot: 'admin/chatbots/running',
  getExpiredChatbot: 'admin/chatbots/expired',
  getToConfigureChatbot: 'admin/chatbots/tobeconfigured',
  deleteChatbot: 'admin/customers/',
};

export default ChatbotRoutes